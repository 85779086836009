import styled from "styled-components";

export const CardWrapper = styled.div`
  .Polaris-LegacyCard {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6rem;
    text-align: center;
  }
`;
export const DatePickerContainer = styled.div`
  .Polaris-LegacyCard {
    box-shadow: none;
  }
  background: var(--p-color-bg);
  border: 1px solid #d3d3d3;
  border-radius: var(--p-border-radius-2);
  margin-top: 2px;
  max-width: 50rem;

  min-width: 30rem;
  position: absolute;
  right: 0;
  top: 5px;
  z-index: 1000;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  th {
    font-weight: 400;
    padding: 5px;
  }
  td {
    padding: 7px;
    width: 100%;
    .Polaris-TextField__Spinner {
      display: none;
    }
  }
`;

export const TableCardWrapper = styled.div`
  .Polaris-LegacyCard__Section {
    padding: 0px;
  }

  #cancellation-attempts-by-customer-1-panel,
  #cancellation-reason-summary-1-panel,
  #pause-reason-summary-1-panel,
  #pause-attempts-summary-1-panel {
    max-height: 476px;
    overflow: scroll;
  }
  margin-bottom: 15px;
`;

export const ReasonCardWrapper = styled.div`
  margin-bottom: 15px;
`;

export const StyledSpan = styled.span`
  .Polaris-LegacyStack .Polaris-LegacyStack--spacingExtraTight > {
    align-items: center;
  }

  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
`;

export const ReasonSummaryTableWrapper = styled.div`
  .Polaris-DataTable__Cell--header {
    font-weight: 500;
  }

  .Polaris-DataTable__Cell--numeric {
    text-align: -webkit-right;
  }
  .Polaris-DataTable__Cell--firstColumn {
    text-align: -webkit-left;
  }
`;

export const ArchivedReasonsTableHead = styled.th`
  color: gray;
`;

export const UserTableWrapper = styled.div`
  .Polaris-DataTable__Cell--header {
    font-weight: 500;
  }
`;

export const CircledLetter = styled.span`
  height: 50%;
  padding-left: 5px;
`;

export const ReasonCounterContainer = styled.div`
  margin-bottom: 41px;
  position: relative;
`;

export const ReasonCounter = styled.span`
  bottom: -7;
  color: "gray";
  position: absolute;
  right: 0;
`;

export const TableHeading = styled.span`
  font-size: 20px;
  font-weight: 600;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 25px;
`;

export const NoCancellationData = styled.div`
  padding: 1.5em;
  text-align: center;
`;

export const LoadingContainer = NoCancellationData;

import { Fade, FormControlLabel, Switch } from "@mui/material";
import {
  Button,
  ContextualSaveBar,
  DataTable,
  Icon,
  Layout,
  LegacyCard,
  LegacyStack,
  Tabs,
  Spinner,
  Text,
  TextContainer,
  Tooltip,
} from "@shopify/polaris";
import { CalendarMajor, ExportMinor } from "@shopify/polaris-icons";
import {
  CancellationActionEnum,
  ICancellationReason,
  IPauseReason,
  IUserCancellation,
  IUserPause,
} from "@smartrr/shared/entities/CancellationRelationship";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { useToast } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import {
  loadCancellationReasons,
  saveCancellationReason,
  updateCancellationReason,
} from "@vendor-app/app/_state/actionCreators/cancellationReason";
import { getCancelledCustomerPurchaseStates } from "@vendor-app/app/_state/actionCreators/customerPurchaseState";
import { updateRetentionToggleForOrg } from "@vendor-app/app/_state/actionCreators/organization";
import {
  deletePauseReasons,
  loadPauseReasons,
  savePauseReason,
  updatePauseReasons,
} from "@vendor-app/app/_state/actionCreators/pauseReason";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { createAndDownloadCsv } from "@vendor-app/utils/createAndDownloadCsv";
import { dateParser } from "@vendor-app/utils/dateFormatters";
import { isEmpty, isEqual, isNil, isUndefined, startCase } from "lodash";
import { DateTime } from "luxon";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

import { typedFrontendVendorApi } from "@smartrr/vendor-portal/src/utils/typedFrontendVendorApi";

import {
  CircledLetter,
  LoadingContainer,
  NoCancellationData,
  ReasonCardWrapper,
  ReasonCounter,
  ReasonCounterContainer,
  ReasonSummaryTableWrapper,
  StyledSpan,
  StyledTable,
  TableCardWrapper,
  TableHeading,
  UserTableWrapper,
} from "./styles";
import { DatePickerOption, IDatePickerRange } from "../../AdminHomeRoute/constants";
import { getRangeByDays } from "../../AdminHomeRoute/utils";
import { ArchivedReasonTable } from "../components/ArchivedReasonTable";
import { CancellationReason } from "../components/CancellationReason";
import { DatePickerRange } from "../components/DatePickerRange";
import { PauseReason } from "../components/PauseReason";
import {
  MAX_REASONS,
  emptyPauseReason,
  emptyReason,
  filterByReference,
  formatCancellationDataforTable,
  formatCancellationReasonTableRows,
  formatPauseReasonTableRows,
  formatUserDataforTable,
  formatUserPauseDataforTable,
  formatUserPauseReasonsTableRows,
  formatUserReasonsTableRows,
  populateDefaultCancellationReasons,
  populateDefaultPauseReasons,
  setMidnightTime,
  updateHasVisitedRetention,
} from "../utils";

export interface IUserSubscriptionCancellationProps extends IUserCancellation {
  cancellationReason: ICancellationReason;
  st: IPurchaseStateWithCustomerRelationship;
}

export interface IUserSubscriptionPauseProps extends IUserPause {
  pauseReason: IPauseReason;
  st: IPurchaseStateWithCustomerRelationship;
}

interface IRetentionCsvExportRows {
  "First name": string;
  "Last name": string;
  Action: string;
  "Selected reason": string;
  "Action date": string;
  "User note": string;
  "Attempt type": string;
}

interface ICancellationReasonsSettingsProps {
  cancellationReasons: ICancellationReason[];
  pauseReasons: IPauseReason[];
}

export function CancellationReasonsSettings({
  cancellationReasons,
  pauseReasons,
}: ICancellationReasonsSettingsProps) {
  const organization = useActiveOrganizationSelector();
  const dispatch = useSmartrrVendorDispatch();
  const uniqueId = v4();

  const { addToast } = useToast();
  const areCancellationReasonsLoading = useSmartrrVendorSelector(state => state.cancellationReasons.isLoading);
  const arePauseReasonsLoading = useSmartrrVendorSelector(state => state.pauseReasons.isLoading);

  const areCancellationsReasonsInitializing = useSmartrrVendorSelector(
    state => state.cancellationReasons.isInitializing
  );

  const [cancellationReasonInput, setCancellationReasonInput] = useState<ICancellationReason[]>([]);

  const [pauseReasonInput, setPauseReasonInput] = useState<IPauseReason[]>([]);
  const [userPauseReasons, setUserPauseReasons] = useState<IUserSubscriptionPauseProps[]>([]);

  const [noReasonUserPauses, setNoReasonUserPauses] = useState<IUserSubscriptionPauseProps[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [userReasons, setUserReasons] = useState<IUserSubscriptionCancellationProps[]>([]);

  const [allCancelledPurchaseStates, setAllCancelledPurchaseStates] = useState<
    IPurchaseStateWithCustomerRelationship[]
  >([]);

  const [deletedReasons, setDeletedReasons] = useState<ICancellationReason[]>([]);
  const [deletedPauseReasons, setDeletedPauseReasons] = useState<IPauseReason[]>([]);

  const [hasReasonIndex, setHasReasonIndex] = useState<boolean>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [isSaving, setIsSaving] = useState<boolean>();

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDates, setSelectedDates] = useState<{ start: Date; end: Date }>(getRangeByDays());

  const [checkedPauseSwitch, setCheckedPauseSwitch] = useState(organization?.hasRetentionPauseReasonEnabled);
  const [checkedReasonSwitch, setCheckedReasonSwitch] = useState(organization?.hasRetentionCancelReasonEnabled);

  const cleanedPurchaseStates =
    userReasons && allCancelledPurchaseStates && filterByReference(allCancelledPurchaseStates!, userReasons!);
  const cancelledCps = cleanedPurchaseStates && formatCancellationDataforTable(cleanedPurchaseStates);

  const [initialHasRetentionCancelReasonEnabled, setInitialHasRetentionCancelReasonEnabled] = useState(
    organization?.hasRetentionCancelReasonEnabled
  );
  const [initialHasRetentionPauseReasonEnabled, setInitialHasRetentionPauseReasonEnabled] = useState(
    organization?.hasRetentionPauseReasonEnabled
  );

  const userCancellations = userReasons && formatUserDataforTable(userReasons);
  const nonArchivedCancellationReasons = cancellationReasonInput?.filter(r => !r.isArchived);

  const nonArchivedPauseReasons = pauseReasonInput && pauseReasonInput?.filter(r => !r.isArchived);

  const hasCancellationReasonChanges =
    cancellationReasons && !isEqual(cancellationReasons, cancellationReasonInput);

  const hasPauseReasonChanges = pauseReasons && !isEqual(pauseReasons, pauseReasonInput);

  const hasCancelReasonEnabledChanges =
    cancellationReasons && !isEqual(initialHasRetentionCancelReasonEnabled, checkedReasonSwitch);

  const hasPauseReasonEnabledChanges =
    pauseReasons && !isEqual(initialHasRetentionPauseReasonEnabled, checkedPauseSwitch);

  const hasChanges =
    hasCancellationReasonChanges ||
    hasPauseReasonChanges ||
    hasPauseReasonEnabledChanges ||
    hasCancelReasonEnabledChanges;

  const [preSelected, setPreSelected] = useState<DatePickerOption>(DatePickerOption.LAST_7_DAYS);

  const [{ start: activeStartDate, end: activeEndDate }, setActiveDates] =
    useState<IDatePickerRange>(getRangeByDays());

  const userReasonsTableData =
    userCancellations && isNil(cancelledCps)
      ? userCancellations
      : cancelledCps && isNil(userCancellations)
        ? cancelledCps
        : cancelledCps && userCancellations
          ? [...cancelledCps, ...userCancellations]
          : undefined;

  const hasArchivedCancellationReasons = useMemo(() => {
    return !isEmpty(
      cancellationReasonInput?.filter(cancellationReason => cancellationReason.isArchived === true)
    );
  }, [cancellationReasonInput]);

  const formattedUserReasonTableRows = formatUserReasonsTableRows(
    userReasonsTableData,
    selectedDates,
    organization
  );
  const pauseTableData = userPauseReasons && formatUserPauseDataforTable(userPauseReasons);
  const pauseTableDataNoReason = noReasonUserPauses && formatUserPauseDataforTable(noReasonUserPauses);

  const userPauseReasonTableData =
    pauseTableData && isNil(pauseTableDataNoReason)
      ? pauseTableData
      : pauseTableDataNoReason && isNil(pauseTableData)
        ? pauseTableDataNoReason
        : pauseTableDataNoReason && pauseTableData
          ? [...pauseTableDataNoReason, ...pauseTableData]
          : undefined;

  const formattedUserPauseReasonTableRows = formatUserPauseReasonsTableRows(
    userPauseReasonTableData,
    selectedDates,
    organization
  );

  const formattedReasonTotalTableRows = formatCancellationReasonTableRows(
    cancellationReasons,
    userReasons,
    selectedDates
  );
  const formattedPauseReasonTotalTableRows = formatPauseReasonTableRows(
    pauseReasons,
    userPauseReasons,
    selectedDates
  );

  const handlePauseSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedPauseSwitch(event.target.checked);
    if (event.target.checked === false) {
      setPauseReasonInput(pauseReasons);
    } else if (event.target.checked === true && isEmpty(pauseReasonInput)) {
      addPauseReason();
    }
  };

  const handleReasonSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedReasonSwitch(event.target.checked);
    if (event.target.checked === false) {
      setCancellationReasonInput(cancellationReasons);
    } else if (
      event.target.checked === true &&
      isEmpty(cancellationReasons) &&
      organization?.hasPopulatedPauseReasons
    ) {
      addReason();
    }
  };

  const hasArchivedPauseReasons = useMemo(() => {
    return !isEmpty(pauseReasonInput?.filter(pauseReason => pauseReason.isArchived === true));
  }, [pauseReasonInput]);

  useEffect(() => {
    if (areCancellationReasonsLoading || arePauseReasonsLoading) {
      return;
    }

    populatePage();

    if (!organization?.hasPopulatedPauseReasons && isEmpty(pauseReasons)) {
      addDefaultPauseReasons();
    }

    //Shops initial retention visits
    if (!organization?.hasVisitedRetention && isEmpty(cancellationReasons)) {
      addDefaultCancellationReasons();
      updateHasVisitedRetention();
    }

    //Adding index order to cancellation reasons
    if (
      !isNil(cancellationReasons) &&
      !isEmpty(cancellationReasons) &&
      cancellationReasons.length > 1 &&
      cancellationReasons.every(r => r.uiIdx === 0)
    ) {
      const updatedReasons = cancellationReasons?.map((reason: ICancellationReason, ind: number) => {
        // handle case where shop is on v1 retention and has more than max reasons
        if (ind > 7) {
          return { ...reason, isArchived: true, uiIdx: ind };
        }
        return { ...reason, uiIdx: ind };
      });

      setCancellationReasonInput(updatedReasons);
      setHasReasonIndex(true);
    } else {
      setCancellationReasonInput(cancellationReasons);
      setPauseReasonInput(pauseReasons);
    }

    setIsLoading(false);
  }, [areCancellationsReasonsInitializing, cancellationReasons, pauseReasons, selectedDates]);

  const getDatePickerButtonText = useCallback(() => {
    return `${selectedDates.start.toLocaleDateString("en-US")} - ${selectedDates.end.toLocaleDateString(
      "en-US"
    )}`;
  }, [selectedDates]);

  const handleTabChange = useCallback(selectedTabIndex => setSelectedTab(selectedTabIndex), []);

  const populatePage = async () => {
    setIsLoadingData(true);
    await Promise.all([
      getCancelledPurchaseStates(),
      getUserCancellations(),
      getUserPauses(),
      getNoReasonUserPauses(),
    ]);
    setIsLoadingData(false);
  };

  const addDefaultCancellationReasons = async () => {
    await populateDefaultCancellationReasons();
    await dispatch(loadCancellationReasons({}));
  };

  const addDefaultPauseReasons = async () => {
    await populateDefaultPauseReasons();
    await dispatch(loadPauseReasons({}));
  };

  // Todo: paginate or date filter CPS and user reason data
  const getCancelledPurchaseStates = async () => {
    const allResults: IPurchaseStateWithCustomerRelationship[] = [];
    let pageNumber = 0;
    let totalPages = 1;

    const start = DateTime.fromJSDate(selectedDates.start).startOf("day").toISO();
    const end = DateTime.fromJSDate(selectedDates.end).endOf("day").toISO();

    try {
      while (pageNumber < totalPages) {
        const subscriptionsPage: any = await getCancelledCustomerPurchaseStates({
          queryParams: {
            pageSize: 250,
            pageNumber,
            orderBy: {
              createdDate: "DESC",
            },
            filterAfter: {
              cancelledAt: start ?? "",
            },
            filterBefore: {
              cancelledAt: end ?? "",
            },
          },
        });

        allResults.push(...subscriptionsPage.body.data);
        pageNumber = subscriptionsPage.body.pageNumber;
        totalPages = subscriptionsPage.body.totalPages;
        pageNumber++;
      }

      setAllCancelledPurchaseStates(
        allResults.filter(
          cps =>
            cps.purchaseStateStatus === "CANCELLED" &&
            !isNil(cps.shopifyId) &&
            !isNil(cps.custRel?.firstName) &&
            !isNil(cps.custRel?.lastName)
        )
      );
    } catch (error) {
      addToast(error.message);
    }
  };

  const getUserCancellations = async () => {
    const allResults: IUserSubscriptionCancellationProps[] = [];
    let pageNumber = 0;
    let totalPages = 1;
    const pageSize = 30;
    const start = DateTime.fromJSDate(selectedDates.start).startOf("day").toJSON();
    const end = DateTime.fromJSDate(selectedDates.end).endOf("day").toJSON();

    try {
      while (pageNumber < totalPages) {
        const res = await typedFrontendVendorApi.getReq("/user-subscription-cancellation", {
          query: {
            pageNumber: pageNumber.toString(),
            pageSize: pageSize.toString(),
            filterAfter: {
              createdDate: start,
            },
            filterBefore: {
              createdDate: end,
            },
          },
        });
        if (res.type === "success") {
          allResults.push(...res.body.data);
          totalPages = res.body.totalPages;
          pageNumber++;
        }
      }
      setUserReasons(
        allResults.filter(
          userCancellation =>
            !isNil(userCancellation.st.shopifyId) &&
            !isNil(userCancellation.st.custRel?.firstName) &&
            !isNil(userCancellation.st.custRel?.lastName)
        )
      );
    } catch (error) {
      addToast(error.message);
    }
  };

  const getUserPauses = async () => {
    const allResults: IUserSubscriptionPauseProps[] = [];
    let pageNumber = 0;
    let totalPages = 1;
    const pageSize = 30;
    const start = DateTime.fromJSDate(selectedDates.start).startOf("day").toJSON();
    const end = DateTime.fromJSDate(selectedDates.end).endOf("day").toJSON();

    try {
      while (pageNumber < totalPages) {
        const res = await typedFrontendVendorApi.getReq("/user-subscription-pause", {
          query: {
            pageNumber: pageNumber.toString(),
            pageSize: pageSize.toString(),
            filterAfter: {
              createdDate: start,
            },
            filterBefore: {
              createdDate: end,
            },
          },
        });
        if (res.type === "success") {
          allResults.push(...res.body.data);
          totalPages = res.body.totalPages;
          pageNumber++;
        }
      }

      setUserPauseReasons(
        allResults.filter(
          userPause =>
            !isNil(userPause.st.shopifyId) &&
            !isNil(userPause.st.custRel?.firstName) &&
            !isNil(userPause.st.custRel?.lastName)
        )
      );
    } catch (error) {
      addToast(error.message);
    }
  };

  const getNoReasonUserPauses = async () => {
    const allResults: IUserSubscriptionPauseProps[] = [];
    let pageNumber = 0;
    let totalPages = 1;
    const pageSize = 30;
    const start = DateTime.fromJSDate(selectedDates.start).startOf("day").toJSON();
    const end = DateTime.fromJSDate(selectedDates.end).endOf("day").toJSON();

    try {
      while (pageNumber < totalPages) {
        const res = await typedFrontendVendorApi.getReq("/user-subscription-no-reason-pause", {
          query: {
            pageNumber: pageNumber.toString(),
            pageSize: pageSize.toString(),
            filterAfter: {
              createdDate: start,
            },
            filterBefore: {
              createdDate: end,
            },
          },
        });
        if (res.type === "success") {
          allResults.push(...res.body.data);
          totalPages = res.body.totalPages;
          pageNumber++;
        }
      }

      setNoReasonUserPauses(
        allResults.filter(
          userPause =>
            !isNil(userPause.st.shopifyId) &&
            !isNil(userPause.st.custRel?.firstName) &&
            !isNil(userPause.st.custRel?.lastName)
        )
      );
    } catch (error) {
      addToast(error.message);
    }
  };

  const onSave = useCallback(async () => {
    const cancellationReasons = cancellationReasonInput?.map(reason => {
      if (reason.action === "None") {
        return { ...reason, action: CancellationActionEnum.CANCEL };
      }

      return reason;
    });

    const emptyCancellationReasons = cancellationReasons?.filter(reason => {
      if (isEmpty(reason.text?.["en-US"])) {
        return reason;
      }
    });

    if (emptyCancellationReasons && !isEmpty(emptyCancellationReasons)) {
      addToast("Add reason text before saving");
      return;
    }

    const emptyPauseReasons = pauseReasonInput?.filter(reason => {
      if (isEmpty(reason.text)) {
        return reason;
      }
    });

    if (emptyPauseReasons && !isEmpty(emptyPauseReasons)) {
      addToast("Add pause reason text before saving");
      return;
    }
    setIsSaving(true);

    const updatedCancellationReasons = cancellationReasons?.filter(reason => !reason.id.includes("new"));
    if (updatedCancellationReasons && !isEmpty(updatedCancellationReasons)) {
      await dispatch(updateCancellationReason(updatedCancellationReasons));
    }

    const updatedPauseReasons = pauseReasonInput?.filter(reason => !reason.id.includes("new"));

    if (updatedPauseReasons && !isEmpty(updatedPauseReasons)) {
      await dispatch(updatePauseReasons(updatedPauseReasons));
    }

    // Clean reason id's before saving to DB
    const newCancellationReasons: ICancellationReason[] | undefined = cancellationReasons
      ?.filter(reason => reason.id.includes("new"))
      .map(reason => ({
        ...reason,
        id: "",
      }));

    if (newCancellationReasons && !isEmpty(newCancellationReasons)) {
      await dispatch(saveCancellationReason(newCancellationReasons));
    }

    if (deletedReasons && !isEmpty(deletedReasons)) {
      const reasonIdsToDelete = deletedReasons.map(reason => reason.id);
      await typedFrontendVendorApi.postReq("/cancellation-reason-delete", {
        reqBody: {
          reasonIdsToDelete,
        },
      });
    }

    //pause

    const newPauseReasons: IPauseReason[] | undefined = pauseReasonInput
      ?.filter(reason => reason.id.includes("new"))
      .map(reason => ({
        ...reason,
        id: "",
      }));

    if (newPauseReasons && !isEmpty(newPauseReasons)) {
      await dispatch(savePauseReason(newPauseReasons));
    }

    if (deletedPauseReasons && !isEmpty(deletedPauseReasons)) {
      const reasonIdsToDelete: string[] = deletedPauseReasons.map(reason => reason.id);
      await dispatch(deletePauseReasons(reasonIdsToDelete));
    }

    const res = await dispatch(updateRetentionToggleForOrg(checkedReasonSwitch!, checkedPauseSwitch!));
    if (res.type === "UPDATED_RETENTION_TOGGLE") {
      setCheckedPauseSwitch(res.payload.hasRetentionPauseReasonEnabled);
      setInitialHasRetentionCancelReasonEnabled(res.payload.hasRetentionCancelReasonEnabled);
      setInitialHasRetentionPauseReasonEnabled(res.payload.hasRetentionPauseReasonEnabled);
      setCheckedReasonSwitch(res.payload.hasRetentionCancelReasonEnabled);
    }
    await dispatch(loadCancellationReasons({}));
    await dispatch(loadPauseReasons({}));
    setIsSaving(false);

    addToast("Reasons saved");
  }, [
    cancellationReasonInput,
    deletedReasons,
    pauseReasonInput,
    deletedPauseReasons,
    checkedReasonSwitch,
    checkedPauseSwitch,
  ]);

  const onDiscard = useCallback(() => {
    setCancellationReasonInput(cancellationReasons);
    setPauseReasonInput(pauseReasons);
    setCheckedPauseSwitch(organization?.hasRetentionPauseReasonEnabled);
    setCheckedReasonSwitch(organization?.hasRetentionCancelReasonEnabled);
    setCheckedPauseSwitch(initialHasRetentionPauseReasonEnabled);
    setCheckedReasonSwitch(initialHasRetentionCancelReasonEnabled);
  }, [cancellationReasons, pauseReasons]);

  //Change Reason Order

  const deleteReason = useCallback(
    (id: string) => {
      const deletedReason = cancellationReasonInput?.find(cancellationReason => cancellationReason.id === id);
      const updatedCancellationReasons = cancellationReasonInput
        ?.filter(cancellationReason => cancellationReason.id !== id)
        .map((reason, ind) => {
          return { ...reason, uiIdx: ind } as ICancellationReason;
        });

      const activeReasons = updatedCancellationReasons.filter(r => !r.isArchived);
      if (isEmpty(updatedCancellationReasons)) {
        setCheckedReasonSwitch(false);
      }
      if (isEmpty(activeReasons)) {
        setCheckedReasonSwitch(false);
      }
      setCancellationReasonInput(updatedCancellationReasons);
      addToast("Reason deleted");
      setDeletedReasons([...deletedReasons, deletedReason!]);
    },
    [addToast, cancellationReasonInput, deletedReasons]
  );

  const deletePauseReason = useCallback(
    (id: string) => {
      const deletedPauseReason = pauseReasonInput?.find(pauseReason => pauseReason.id === id);
      const updatedPauseReasons = pauseReasonInput
        ?.filter(pauseReason => pauseReason.id !== id)
        .map((reason, ind) => {
          return { ...reason, uiIdx: ind } as IPauseReason;
        });

      const activeReasons = updatedPauseReasons.filter(r => !r.isArchived);
      if (isEmpty(updatedPauseReasons)) {
        setCheckedPauseSwitch(false);
      }
      if (isEmpty(activeReasons)) {
        setCheckedPauseSwitch(false);
      }
      setPauseReasonInput(updatedPauseReasons);
      addToast("Reason deleted");
      setDeletedPauseReasons([...deletedPauseReasons, deletedPauseReason!]);
    },
    [pauseReasonInput, addToast, deletedPauseReasons]
  );

  const addReason = useCallback(
    (reasonsAmount?: number) => {
      if (reasonsAmount !== MAX_REASONS) {
        if (nonArchivedCancellationReasons && nonArchivedCancellationReasons?.length >= MAX_REASONS) {
          addToast("You already have the maximum amount of reasons");
          return;
        }
      }

      //add temporary id to reasons
      const defaultReason: ICancellationReason = {
        ...emptyReason,
        uiIdx: cancellationReasonInput?.length!,
        id: `new-${uniqueId}`,
      };
      setCancellationReasonInput(prevState => [defaultReason, ...prevState!]);
    },
    [nonArchivedCancellationReasons, cancellationReasonInput]
  );

  const addPauseReason = useCallback(
    (reasonsAmount?: number) => {
      if (reasonsAmount !== MAX_REASONS) {
        if (nonArchivedPauseReasons && nonArchivedPauseReasons?.length >= MAX_REASONS) {
          addToast("You already have the maximum amount of reasons");
          return;
        }
      }

      //add temporary id to reasons
      const defaultReason: IPauseReason = {
        ...emptyPauseReason,
        uiIdx: pauseReasonInput?.length!,
        id: `new-${uniqueId}`,
      };
      setPauseReasonInput(prevState => [defaultReason, ...prevState!]);
    },
    [nonArchivedPauseReasons, pauseReasonInput]
  );

  const updateReason = useCallback(
    (
      id: string,
      action:
        | "updateReasonText"
        | "updateAction"
        | "updateReasonDiscount"
        | "archiveReason"
        | "updateHasCustomerPrompt",
      value: string | CancellationActionEnum | number | boolean
    ) => {
      if (action === "archiveReason" && !value && nonArchivedCancellationReasons?.length! >= MAX_REASONS) {
        addToast("You already have the maximum amount of reasons");
        return;
      }
      const updatedInput = cancellationReasonInput?.map(cancellationReason => {
        const isMatch = id === cancellationReason.id;
        switch (action) {
          case "updateReasonText": {
            if (isMatch) {
              return { ...cancellationReason, text: { "en-US": value } };
            }
            return cancellationReason;
          }
          case "updateAction": {
            if (isMatch) {
              return { ...cancellationReason, action: value };
            }
            return cancellationReason;
          }
          case "updateReasonDiscount": {
            if (isMatch) {
              return { ...cancellationReason, discount: value };
            }
            return cancellationReason;
          }
          case "updateHasCustomerPrompt": {
            if (isMatch) {
              return { ...cancellationReason, hasCustomerPrompt: value };
            }
            return cancellationReason;
          }
          case "archiveReason": {
            if (isMatch) {
              if (value) {
                //Archiving reason
                return {
                  ...cancellationReason,
                  isArchived: value,
                };
              } else if (!value) {
                //setting reason to active
                return {
                  ...cancellationReason,
                  isArchived: value,
                  uiIdx: nonArchivedCancellationReasons?.length,
                };
              }
            }
            return {
              ...cancellationReason,
            };
          }
        }
      });

      const activeReasons = updatedInput?.filter(r => !r?.isArchived);

      if (action === "archiveReason") {
        const archivedReasons = updatedInput?.filter(r => r?.isArchived);
        //setting index for active reasons without archived
        const activeIndexedReasons = activeReasons
          ?.filter(r => !r?.isArchived)
          .map((reason, ind) => {
            return { ...reason, uiIdx: ind } as ICancellationReason;
          });
        //adding archived reasons to be displayed on archived reason table
        setCancellationReasonInput([...activeIndexedReasons!, ...archivedReasons!] as ICancellationReason[]);
      } else {
        setCancellationReasonInput(updatedInput as ICancellationReason[]);
      }

      if (action === "archiveReason" && value) {
        addToast("Reason archived");
        if (isEmpty(activeReasons)) {
          setCheckedReasonSwitch(false);
        }
      } else if (action === "archiveReason" && !value) {
        addToast("Reason set to active");
      }
    },
    [cancellationReasonInput, nonArchivedCancellationReasons]
  );
  const updatePauseReason = useCallback(
    (
      id: string,
      action: "updateReasonText" | "archiveReason" | "updateHasCustomerPrompt",
      value: string | boolean
    ) => {
      if (action === "archiveReason" && !value && nonArchivedPauseReasons?.length! >= MAX_REASONS) {
        addToast("You already have the maximum amount of reasons");
        return;
      }
      const updatedInput = pauseReasonInput?.map(pauseReason => {
        const isMatch = id === pauseReason.id;
        switch (action) {
          case "updateReasonText": {
            if (isMatch) {
              return { ...pauseReason, text: value };
            }
            return pauseReason;
          }
          case "updateHasCustomerPrompt": {
            if (isMatch) {
              return { ...pauseReason, hasCustomerPrompt: value };
            }
            return pauseReason;
          }
          case "archiveReason": {
            if (isMatch) {
              if (value) {
                //Archiving reason
                return {
                  ...pauseReason,
                  isArchived: value,
                };
              } else if (!value) {
                //setting reason to active
                return {
                  ...pauseReason,
                  isArchived: value,
                  uiIdx: nonArchivedPauseReasons?.length,
                };
              }
            }
            return {
              ...pauseReason,
            };
          }
        }
      });
      const activeReasons = updatedInput?.filter(r => !r?.isArchived);

      if (action === "archiveReason") {
        const archivedReasons = updatedInput?.filter(r => r?.isArchived);
        //setting index for active reasons without archived
        const activeIndexedReasons = activeReasons
          ?.filter(r => !r?.isArchived)
          .map((reason, ind) => {
            return { ...reason, uiIdx: ind } as IPauseReason;
          });
        //adding archived reasons to be displayed on archived reason table
        setPauseReasonInput([...activeIndexedReasons!, ...archivedReasons!] as IPauseReason[]);
      } else {
        setPauseReasonInput(updatedInput as IPauseReason[]);
      }

      if (action === "archiveReason" && value) {
        addToast("Reason archived");
        if (isEmpty(activeReasons)) {
          setCheckedPauseSwitch(false);
        }
      } else if (action === "archiveReason" && !value) {
        addToast("Reason set to active");
      }
    },
    [pauseReasonInput, nonArchivedPauseReasons]
  );

  const handleDatePickerClick = () => {
    setShowDatePicker(!showDatePicker);
  };

  const userReasonsToCSV = () => {
    const cancelRows: IRetentionCsvExportRows[] =
      userReasonsTableData
        ?.filter(row => {
          const rowDate = new Date(row.date);
          const selectedEndDate = setMidnightTime(selectedDates.end);
          return rowDate >= selectedDates.start && rowDate <= selectedEndDate;
        })
        .map(userData => ({
          "First name": userData.customer.firstName!,
          "Last name": userData.customer.lastName!,
          Action: startCase(userData.cancellation.action.toLocaleLowerCase())!,
          "Selected reason": userData.cancellation.reasonText || "No reason selected",
          "Action date": dateParser(userData.date, organization?.billingTimezone)!,
          "User note": userData.cancellation.userNote!,
          "Attempt type": "Cancel",
        })) || [];

    const pauseRows: IRetentionCsvExportRows[] =
      userPauseReasonTableData
        ?.filter(row => {
          const rowDate = new Date(row.date);
          const selectedEndDate = setMidnightTime(selectedDates.end);
          return rowDate >= selectedDates.start && rowDate <= selectedEndDate;
        })
        .map(userData => ({
          "First name": userData.customer.firstName!,
          "Last name": userData.customer.lastName!,
          Action: "Paused",
          "Selected reason": userData.pause.reasonText || "No reason selected",
          "Action date": dateParser(userData.date, organization?.billingTimezone)!,
          "User note": userData.pause.userNote!,
          "Attempt type": "Pause",
        })) || [];

    if (!isEmpty(userReasonsTableData) || !isEmpty(pauseTableData)) {
      const combinedRows = cancelRows.concat(pauseRows);
      createAndDownloadCsv(combinedRows, "retention_export");
    } else {
      addToast("No data to export");
    }
  };

  const buttonText = getDatePickerButtonText();

  const responseHeader = (
    <p>
      <span style={{ fontWeight: 500 }}>Number of Responses</span>{" "}
      <em style={{ color: "gray" }}>({buttonText})</em>
    </p>
  );
  const noCancellationReasons = isEmpty(userReasons) && isEmpty(cancelledCps);
  const noPauseReasons = isEmpty(userPauseReasons) && isEmpty(noReasonUserPauses);

  const tabs: {
    id: string;
    value: any;
    accessibilityLabel: string;
    content: any;
  }[] = [
    {
      id: "cancellation-attempts-by-customer-1",
      value: isLoadingData ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : noCancellationReasons ? (
        <NoCancellationData>
          <p>When customers attempt to cancel, their reasons will show here.</p>
        </NoCancellationData>
      ) : (
        <UserTableWrapper>
          <DataTable
            columnContentTypes={["text", "numeric", "text", "text", "text"]}
            headings={["Customer", "Subscription ID", "Cancelation Reason", "Date", "Action Taken"]}
            rows={formattedUserReasonTableRows! || []}
            sortable={[false, false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={4}
          />
        </UserTableWrapper>
      ),
      accessibilityLabel: "Cancellation attempts by customer",
      content: "Cancellation attempts by customer",
    },
    {
      id: "cancellation-reason-summary-1",
      value: (
        <ReasonSummaryTableWrapper>
          <DataTable
            columnContentTypes={["text", "numeric"]}
            headings={["Reasons", responseHeader]}
            rows={formattedReasonTotalTableRows!}
            sortable={[false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={4}
          />
        </ReasonSummaryTableWrapper>
      ),
      accessibilityLabel: "Cancellation attempts by customer",
      content: "Cancellation reason summary",
    },
    {
      id: "pause-attempts-summary-1",
      value: isLoadingData ? (
        <LoadingContainer>
          <Spinner />
        </LoadingContainer>
      ) : noPauseReasons ? (
        <NoCancellationData>
          <p>When customers attempt to pause, their reasons will show here.</p>
        </NoCancellationData>
      ) : (
        <UserTableWrapper>
          <DataTable
            columnContentTypes={["text", "numeric", "text", "text", "text"]}
            headings={["Customer", "Subscription ID", "Pause Reason", "Date", "Action Taken"]}
            rows={formattedUserPauseReasonTableRows!}
            sortable={[false, false, false, false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={4}
          />
        </UserTableWrapper>
      ),
      accessibilityLabel: "Pause attempts by customer",
      content: "Pause attempts by customer",
    },
    {
      id: "pause-reason-summary-1",
      value: (
        <ReasonSummaryTableWrapper>
          <DataTable
            columnContentTypes={["text", "numeric"]}
            headings={["Reasons", responseHeader]}
            rows={formattedPauseReasonTotalTableRows!}
            sortable={[false, false]}
            defaultSortDirection="descending"
            initialSortColumnIndex={4}
          />
        </ReasonSummaryTableWrapper>
      ),
      accessibilityLabel: "Pause attempts by customer",
      content: "Pause reason summary",
    },
  ];

  if (isLoading || isUndefined(userReasons)) {
    return (
      <LegacyStack alignment="center" distribution="center">
        <Spinner />
      </LegacyStack>
    );
  }

  return (
    <Layout>
      <Layout.Section>
        <LegacyStack vertical spacing="loose">
          <Text variant="headingMd" as="h2">
            <LegacyStack distribution="equalSpacing">
              <h1 className="Polaris-Header-Title">Retention</h1>
              <StyledSpan>
                <LegacyStack spacing="loose">
                  <div style={{ marginTop: ".7rem" }} onClick={userReasonsToCSV}>
                    <LegacyStack>
                      <Icon source={ExportMinor} color="base" />
                      <span style={{ alignContent: "baseline" }}>Export to CSV</span>
                    </LegacyStack>
                  </div>
                  <span>
                    <Button
                      id="date-picker-button"
                      disabled={isLoadingData}
                      icon={CalendarMajor}
                      onClick={handleDatePickerClick}
                    >
                      {preSelected === DatePickerOption.CUSTOM
                        ? `${selectedDates.start.toLocaleDateString(
                            "en-US"
                          )} - ${selectedDates.end.toLocaleDateString("en-US")}`
                        : preSelected}
                    </Button>
                  </span>
                </LegacyStack>
              </StyledSpan>
            </LegacyStack>
          </Text>
          <div style={{ position: "relative" }}>
            {!!showDatePicker && (
              <DatePickerRange
                selectedModalDates={selectedDates}
                setSelectedModalDates={setSelectedDates}
                setShowDatePicker={setShowDatePicker}
                preSelected={preSelected}
                setPreSelected={setPreSelected}
                setActiveDates={setActiveDates}
                activeDates={{ start: activeStartDate, end: activeEndDate }}
              />
            )}
          </div>
          <TableCardWrapper>
            <LegacyCard>
              <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange}>
                <LegacyCard.Section>{tabs[selectedTab].value}</LegacyCard.Section>
              </Tabs>
            </LegacyCard>
          </TableCardWrapper>
          <LegacyStack distribution="equalSpacing">
            <LegacyStack distribution="equalSpacing">
              <TableHeading>Cancel reasons</TableHeading>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkedReasonSwitch}
                    onChange={handleReasonSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                    color="success"
                  />
                }
                label={checkedReasonSwitch ? "On" : "Off"}
                sx={{
                  color: "gray",
                  marginTop: "-7px",
                }}
              />
            </LegacyStack>
            <Button
              disabled={!checkedReasonSwitch || nonArchivedCancellationReasons.length >= MAX_REASONS}
              primary
              onClick={() => addReason()}
            >
              {" "}
              Add reason
            </Button>
            <Text as="p" variant="bodyMd" color="subdued">
              Add up to eight reasons. Reason order will be randomized in the customer account portal.
            </Text>
          </LegacyStack>
          <Fade timeout={{ enter: 800, exit: 300 }} in={checkedReasonSwitch} unmountOnExit={true}>
            <ReasonCardWrapper>
              <LegacyCard sectioned>
                <StyledTable>
                  <tr>
                    <th>Reason</th>
                    <th>
                      Retention Action
                      <Tooltip
                        preferredPosition="above"
                        content={
                          <TextContainer>
                            Cancel will <strong>always</strong> be displayed as an action, but you can add an
                            additional retention action here as a tactic to try and help retain customers.
                          </TextContainer>
                        }
                      >
                        <CircledLetter>ⓘ</CircledLetter>
                      </Tooltip>
                    </th>
                    <th>Discount</th>
                    <th>
                      Prompt
                      <Tooltip
                        preferredPosition="above"
                        content="Set to YES if you would like to display a prompt asking your customer to provide additional written feedback once they've selected a cancellation reason."
                      >
                        <CircledLetter>ⓘ</CircledLetter>
                      </Tooltip>
                    </th>
                    <th>{""}</th>
                  </tr>
                  {!isLoading &&
                    !!cancellationReasonInput &&
                    cancellationReasonInput
                      .sort((a, b) => a.uiIdx - b.uiIdx)
                      .map(cancellationReason => {
                        if (!cancellationReason.isArchived) {
                          return (
                            <CancellationReason
                              key={cancellationReason.id}
                              cancellationReason={cancellationReason}
                              deleteReason={deleteReason}
                              userReasons={userReasons}
                              updateReason={updateReason}
                              cancellationReasonInput={cancellationReasonInput}
                              addReason={addReason}
                            />
                          );
                        }
                      })}
                </StyledTable>
                <ReasonCounterContainer>
                  <ReasonCounter> {`${nonArchivedCancellationReasons?.length}/${MAX_REASONS}`}</ReasonCounter>
                </ReasonCounterContainer>
                {!!hasArchivedCancellationReasons && (
                  <ArchivedReasonTable
                    cancellationReasonInput={cancellationReasonInput}
                    updateReason={updateReason}
                  />
                )}
              </LegacyCard>
            </ReasonCardWrapper>
          </Fade>
          <LegacyStack distribution="equalSpacing">
            <LegacyStack>
              <TableHeading>Pause reasons</TableHeading>
              <FormControlLabel
                control={
                  <Switch
                    checked={checkedPauseSwitch}
                    onChange={handlePauseSwitchChange}
                    inputProps={{ "aria-label": "controlled" }}
                    color="success"
                  />
                }
                label={checkedPauseSwitch ? "On" : "Off"}
                sx={{
                  color: "gray",
                  marginTop: "-7px",
                }}
              />
            </LegacyStack>
            <Button
              disabled={!checkedPauseSwitch || nonArchivedPauseReasons.length >= MAX_REASONS}
              primary
              onClick={() => addPauseReason()}
            >
              {" "}
              Add reason
            </Button>
          </LegacyStack>
          <Fade timeout={{ enter: 800, exit: 300 }} in={checkedPauseSwitch} unmountOnExit={true}>
            <div>
              <LegacyCard sectioned>
                <StyledTable>
                  <tr>
                    <th>Reason</th>
                    <th>
                      Prompt
                      <Tooltip
                        preferredPosition="above"
                        content="Set to YES if you would like to display a prompt asking your customer to provide additional written feedback once they've selected a pause reason."
                      >
                        <CircledLetter>ⓘ</CircledLetter>
                      </Tooltip>
                    </th>
                    <th>{""}</th>
                  </tr>
                  {!isLoading &&
                    !!pauseReasonInput &&
                    pauseReasonInput
                      .sort((a, b) => a.uiIdx - b.uiIdx)
                      .map(pauseReason => {
                        if (!pauseReason.isArchived) {
                          return (
                            <PauseReason
                              key={pauseReason.id}
                              pauseReason={pauseReason}
                              deleteReason={deletePauseReason}
                              userReasons={userPauseReasons!}
                              updateReason={updatePauseReason}
                              pauseReasonInput={pauseReasonInput}
                              addReason={addPauseReason}
                            />
                          );
                        }
                      })}
                </StyledTable>
                <ReasonCounterContainer>
                  <ReasonCounter> {`${nonArchivedPauseReasons?.length}/${MAX_REASONS}`}</ReasonCounter>
                </ReasonCounterContainer>
                {!!hasArchivedPauseReasons && (
                  <ArchivedReasonTable
                    pauseReasonInput={pauseReasonInput}
                    updatePauseReason={updatePauseReason}
                    pause
                  />
                )}
              </LegacyCard>
            </div>
          </Fade>
        </LegacyStack>
      </Layout.Section>
      {!!hasChanges && (
        <ContextualSaveBar
          message="Unsaved changes"
          saveAction={{
            onAction: onSave,
            loading: false,
            disabled: isSaving,
          }}
          discardAction={{
            onAction: onDiscard,
            disabled: hasReasonIndex,
          }}
        />
      )}{" "}
    </Layout>
  );
}

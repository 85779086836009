import { ICancellationReason } from "@smartrr/shared/entities/CancellationRelationship";
import { ILoadPaginatedForOrg } from "@smartrr/shared/utils/paginatedQuery";

import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const loadCancellationReasons =
  ({}: ILoadPaginatedForOrg): SmartrrThunkAction<
    "LOADING_CANCELLATION_REASONS" | "ERROR_LOADING_CANCELLATION_REASONS" | "LOADED_CANCELLATION_REASONS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/cancellation-reasons"),
      () =>
        dispatch({
          type: "LOADING_CANCELLATION_REASONS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_CANCELLATION_REASONS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      cancellationReasons =>
        dispatch({
          type: "LOADED_CANCELLATION_REASONS",
          payload: {
            cancellationReasons,
          },
        })
    );

export const saveCancellationReason =
  (
    cancellationReasons: ICancellationReason[]
  ): SmartrrThunkAction<
    "SAVING_CANCELLATION_REASON" | "ERROR_SAVING_CANCELLATION_REASON" | "SAVED_CANCELLATION_REASON"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/cancellation-reason", {
        reqBody: { cancellationReasons },
      }),
      () =>
        dispatch({
          type: "SAVING_CANCELLATION_REASON",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_SAVING_CANCELLATION_REASON",
          payload: {
            errorMessage: failure.message,
          },
        }),
      cancellationReasons =>
        dispatch({
          type: "SAVED_CANCELLATION_REASON",
          payload: {
            cancellationReasons,
          },
        })
    );

export const updateCancellationReason =
  (
    cancellationReasons: ICancellationReason[]
  ): SmartrrThunkAction<
    "UPDATING_CANCELLATION_REASON" | "ERROR_UPDATING_CANCELLATION_REASON" | "UPDATED_CANCELLATION_REASON"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/cancellation-reason/", {
        reqBody: { cancellationReasons },
      }),
      () =>
        dispatch({
          type: "UPDATING_CANCELLATION_REASON",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_UPDATING_CANCELLATION_REASON",
          payload: {
            errorMessage: failure.message,
          },
        }),
      cancellationReason =>
        dispatch({
          type: "UPDATED_CANCELLATION_REASON",
          payload: {
            cancellationReason,
          },
        })
    );

export const deleteCancellationReason =
  ({
    cancellationReasonId,
  }: {
    cancellationReasonId: string;
  }): SmartrrThunkAction<
    "DELETING_CANCELLATION_REASON" | "ERROR_DELETING_CANCELLATION_REASON" | "DELETED_CANCELLATION_REASON"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.deleteReq("/cancellation-reason/:cancellationReasonId", {
        params: {
          cancellationReasonId,
        },
      }),
      () =>
        dispatch({
          type: "DELETING_CANCELLATION_REASON",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_DELETING_CANCELLATION_REASON",
          payload: {
            errorMessage: failure.message,
          },
        }),
      () =>
        dispatch({
          type: "DELETED_CANCELLATION_REASON",
          payload: {
            cancellationReasonId,
          },
        })
    );

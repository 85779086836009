import { DatePicker, Icon, LegacyCard, LegacyStack, OptionList, Range, Text } from "@shopify/polaris";
import { ArrowRightMinor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { DatePickerOption, IDatePickerRange, options } from "../../AdminHomeRoute/constants";
import {
  areRangesEqual,
  formatDateForPrint,
  getRangeByDays,
  getRangeByMonths,
  getRangeByYears,
  getToday,
  getYesterday,
} from "../../AdminHomeRoute/utils";

interface IDatePickerRangeProps {
  selectedModalDates: IDatePickerRange;
  setSelectedModalDates: React.Dispatch<React.SetStateAction<IDatePickerRange>>;
  setShowDatePicker: React.Dispatch<React.SetStateAction<boolean>>;
  preSelected: DatePickerOption;
  setPreSelected: React.Dispatch<React.SetStateAction<DatePickerOption>>;
  setActiveDates: React.Dispatch<React.SetStateAction<IDatePickerRange>>;
  activeDates: IDatePickerRange;
}

const DatePickerContainer = styled.div`
  border: 1px solid #d3d3d3;
  border-radius: var(--p-border-radius-2);
  position: absolute;
  right: 0;
  z-index: 35;
  .Polaris-LegacyCard {
    .Polaris-LegacyCard__Section {
      padding-bottom: 0px;
    }
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .Polaris-LegacyCard {
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    padding: 8px;
    width: 230px;
  }
`;

const DatePickerWrapper = styled.div`
  height: 100%;
  max-width: 550px;
  .Polaris-DatePicker {
    margin: auto 0;
  }
`;

export function DatePickerRange({
  selectedModalDates,
  setSelectedModalDates,
  setShowDatePicker,
  preSelected,
  setPreSelected,
  setActiveDates,
  activeDates,
}: IDatePickerRangeProps): JSX.Element {
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
  const initialYear = currentMonth === 0 ? currentYear - 1 : currentYear;
  const [month, setMonth] = useState(previousMonth); // Displaying previous month & current month
  const [year, setYear] = useState(initialYear);
  const [tempPreSelected, setTempPreSelected] = useState<DatePickerOption>(preSelected); // Adding this as a holding value just in case user hits cancel, can revert to previous value
  const [beforeStartDate, setBeforeStartDate] = useState(selectedModalDates.start);
  const areSelectedDatesEqual = selectedModalDates.start.getTime() === selectedModalDates.end.getTime();

  const todayRange = useMemo(() => getToday(), []);
  const yesterdayRange = useMemo(() => getYesterday(), []);
  const sevenDayRange = useMemo(() => getRangeByDays(), []);
  const thirtyDayRange = useMemo(() => getRangeByDays(30), []);
  const ninetyDayRange = useMemo(() => getRangeByDays(90), []);
  const oneMonthRange = useMemo(() => getRangeByMonths(1), []);
  const oneYearRange = useMemo(() => getRangeByYears(1), []);

  const setNewDates = useCallback(
    (
      range: IDatePickerRange,
      getRange: (daysLookUp?: number, startDate?: Date | undefined) => IDatePickerRange,
      lookup?: number
    ) => {
      const { start, end } = range;
      setSelectedModalDates({
        start,
        end,
      });
      setBeforeStartDate(end);
    },
    [preSelected]
  );

  const handleSelectChange = useCallback(
    value => {
      switch (value) {
        case DatePickerOption.LAST_7_DAYS: {
          setNewDates(sevenDayRange, getRangeByDays);
          break;
        }
        case DatePickerOption.TODAY: {
          setNewDates(todayRange, getYesterday);
          break;
        }
        case DatePickerOption.YESTERDAY: {
          setNewDates(yesterdayRange, getYesterday);
          break;
        }
        case DatePickerOption.LAST_30_DAYS: {
          setNewDates(thirtyDayRange, getRangeByDays, 30);
          break;
        }
        case DatePickerOption.LAST_90_DAYS: {
          setNewDates(ninetyDayRange, getRangeByDays, 90);
          break;
        }
        case DatePickerOption.LAST_MONTH: {
          setNewDates(oneMonthRange, getRangeByMonths, 1);
          break;
        }
        case DatePickerOption.LAST_YEAR: {
          setNewDates(oneYearRange, getRangeByYears, 1);
          break;
        }
        default: {
          setNewDates(sevenDayRange, getRangeByDays);
          break;
        }
      }
      setTempPreSelected(value);
    },
    [preSelected]
  );

  const handleDatePickerChange = useCallback((date: Range) => setSelectedModalDates(date), []);

  const handleMonthChange = useCallback(
    (selectedMonth: number, _selectedYear: number) => {
      // Conditions to switch two months by one click and display previous & current months properly
      if (selectedMonth === 11 && month === 0) {
        setMonth(10);
        setYear(prevYearState => prevYearState - 1);
      } else if (selectedMonth === 11 && month === 10) {
        setMonth(0);
        setYear(prevYearState => prevYearState + 1);
      } else if (selectedMonth === 0 && month === 11) {
        setMonth(1);
        setYear(prevYearState => prevYearState + 1);
      } else if (selectedMonth === 0 && month === 1) {
        setMonth(11);
        setYear(prevYearState => prevYearState - 1);
      } else {
        setMonth(selectedMonth + (selectedMonth - month)); // used to find out whether previous or next month should be shown
      }
    },
    [month]
  );

  const onDateApply = useCallback(() => {
    setActiveDates({ start: selectedModalDates.start, end: selectedModalDates.end });
    setPreSelected(tempPreSelected);

    setShowDatePicker(false);
  }, [selectedModalDates, beforeStartDate, preSelected, tempPreSelected]);

  const onCancel = useCallback(() => {
    setSelectedModalDates(activeDates);
    setTempPreSelected(preSelected);
    setShowDatePicker(false);
  }, []);

  useEffect(() => {
    /**
     * if user is setting dates manually, sidebar option should automatically adjust if selected dates are also presets
     * ie: Selecting today on calendar should auto select 'Today' on sidebar
     **/

    if (areRangesEqual(selectedModalDates, todayRange)) {
      //
      setTempPreSelected(DatePickerOption.TODAY);
    } else if (areRangesEqual(selectedModalDates, yesterdayRange)) {
      setTempPreSelected(DatePickerOption.YESTERDAY);
    } else if (areRangesEqual(selectedModalDates, sevenDayRange)) {
      setTempPreSelected(DatePickerOption.LAST_7_DAYS);
    } else if (areRangesEqual(selectedModalDates, thirtyDayRange)) {
      setTempPreSelected(DatePickerOption.LAST_30_DAYS);
    } else if (areRangesEqual(selectedModalDates, ninetyDayRange)) {
      setTempPreSelected(DatePickerOption.LAST_90_DAYS);
    } else if (areRangesEqual(selectedModalDates, oneMonthRange)) {
      setTempPreSelected(DatePickerOption.LAST_MONTH);
    } else if (areRangesEqual(selectedModalDates, oneYearRange)) {
      setTempPreSelected(DatePickerOption.LAST_YEAR);
    } else {
      setTempPreSelected(DatePickerOption.CUSTOM);
    }
  }, [selectedModalDates]);

  return (
    <DatePickerContainer>
      <LegacyCard
        sectioned
        secondaryFooterActions={[
          {
            content: "Cancel",
            onAction: onCancel,
          },
        ]}
        primaryFooterAction={{ content: "Apply", onAction: onDateApply }}
      >
        <LegacyStack>
          <OptionList
            options={options}
            onChange={val => handleSelectChange(val[0])}
            selected={[tempPreSelected]}
          />
          <DatePickerWrapper>
            {/* using MUI's Stack since Polaris' drives me mad */}
            <LegacyStack vertical>
              <DateWrapper>
                <LegacyCard>
                  <Text as="span" variant="bodyMd">
                    {formatDateForPrint(selectedModalDates.start)}
                  </Text>
                </LegacyCard>
                {areSelectedDatesEqual ? null : (
                  <React.Fragment>
                    <Icon source={ArrowRightMinor} color="base" />
                    <LegacyCard>
                      <Text as="span" variant="bodyMd">
                        {formatDateForPrint(selectedModalDates.end)}
                      </Text>
                    </LegacyCard>
                  </React.Fragment>
                )}
              </DateWrapper>
              <DatePicker
                month={month}
                year={year}
                onChange={handleDatePickerChange}
                onMonthChange={handleMonthChange}
                selected={selectedModalDates}
                disableDatesAfter={now}
                multiMonth
                allowRange
              />
            </LegacyStack>
          </DatePickerWrapper>
        </LegacyStack>
      </LegacyCard>
    </DatePickerContainer>
  );
}

import { ISODateString } from "./ISODateString";

export enum CancellationReasonTypeEnum {
  PRODUCT = "Product",
  PRICE = "Price",
  DELIVERY = "Delivery",
  SERVICE = "Service",
  ALTERNATIVES = "Alternatives",
  OTHER = "Other",
}

export enum CancellationActionEnum {
  GIFT = "GIFT",
  SWAP = "SWAP",
  CHANGE_DATE = "CHANGE DATE",
  CANCEL = "CANCEL",
  DISCOUNT = "DISCOUNT",
  FREQUENCY_UPDATE = "FREQUENCY UPDATE",
  SKIP = "SKIP",
  CHANGE_ADDRESS = "CHANGE ADDRESS",
}

interface ITranslatable {
  [key: string]: string;
}

export interface ICancellationReason {
  id: string;
  reasonType: string;
  text?: ITranslatable | undefined;
  action: string;
  uiIdx: number;
  hasCustomerPrompt: boolean;
  discount?: number;
  isArchived: boolean;
}

export interface IPauseReason {
  id: string;
  text?: string;
  uiIdx: number;
  hasCustomerPrompt: boolean;
  isArchived: boolean;
}

export interface IUserCancellation {
  reasonText: string;
  action: string;
  stId: string;
  cancellationReasonId: string;
  createdDate?: ISODateString;
}
export interface IUserPause {
  reasonText: string;
  stId: string;
  pauseReasonId: string;
  createdDate?: ISODateString;
}

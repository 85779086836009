import { Banner, Icon, Modal, Select, TextContainer, TextField, Tooltip } from "@shopify/polaris";
import { ArchiveMajor, DeleteMinor } from "@shopify/polaris-icons";
import { CancellationActionEnum, ICancellationReason } from "@smartrr/shared/entities/CancellationRelationship";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { IUserSubscriptionCancellationProps } from "@vendor-app/app/AdminRoute//AdminChurnPreventionRoute/CancellationReasonsSettings";
import { CancellationActionOptions } from "@vendor-app/app/AdminRoute/AdminChurnPreventionRoute/utils";

const CancellationPromptOptions = [
  {
    label: "YES",
    value: "YES",
  },
  {
    label: "NO",
    value: "NO",
  },
];

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6.5px;
`;

interface CancellationReasonProps {
  cancellationReason?: ICancellationReason;
  deleteReason: (id: string) => void;
  userReasons: IUserSubscriptionCancellationProps[];
  addReason: (reasonsAmount?: number) => void;
  updateReason: (
    id: string,
    action:
      | "updateReasonText"
      | "updateAction"
      | "updateReasonDiscount"
      | "archiveReason"
      | "updateHasCustomerPrompt",
    value: string | CancellationActionEnum | number | boolean
  ) => void;
  cancellationReasonInput: ICancellationReason[];
}

export function CancellationReason({
  cancellationReason,
  deleteReason,
  userReasons,
  updateReason,
  cancellationReasonInput,
  addReason,
}: CancellationReasonProps) {
  const [showAssociatedAttempts, setShowAssociatedAttempts] = useState(false);
  const [showDeleteReasonModal, setShowDeleteReasonModal] = useState(false);
  const [hasWarned, setHasWarned] = useState(false);

  const associatedUserReasons = userReasons.filter(
    userReason => userReason.cancellationReason.id === cancellationReason?.id
  );

  const handleChange = useCallback(
    () => setShowAssociatedAttempts(prevState => !prevState),
    [showAssociatedAttempts]
  );

  const promptValue = useMemo(() => {
    return cancellationReason?.hasCustomerPrompt ? "YES" : "NO";
  }, [cancellationReason]);

  return (
    <React.Fragment>
      {!!cancellationReason && (
        <tr>
          <td style={{ width: 364 }}>
            <TextField
              autoComplete="off"
              label=""
              value={cancellationReason?.text?.["en-US"] as string}
              onChange={val => {
                if (!isEmpty(associatedUserReasons) && !hasWarned) {
                  setShowAssociatedAttempts(true);
                  setHasWarned(true);
                  return;
                }
                updateReason(cancellationReason.id, "updateReasonText", val);
              }}
              maxLength={40}
              showCharacterCount
            />
          </td>

          <td style={{ width: 165 }}>
            <Select
              label=""
              options={CancellationActionOptions}
              onChange={val => {
                updateReason(
                  cancellationReason.id,
                  "updateAction",
                  val === "None" ? CancellationActionEnum.CANCEL : (val as CancellationActionEnum)
                );
              }}
              value={cancellationReason.action}
            />
          </td>
          <td style={{ width: 78 }}>
            <TextField
              autoComplete="off"
              label=""
              value={
                cancellationReason?.action === CancellationActionEnum.DISCOUNT
                  ? cancellationReason?.discount?.toString()!
                  : ""
              }
              onChange={val => updateReason(cancellationReason.id, "updateReasonDiscount", +val)}
              maxLength={2}
              type="number"
              disabled={cancellationReason?.action !== CancellationActionEnum.DISCOUNT}
              suffix="%"
            />
          </td>
          <td style={{ width: 78 }}>
            <Select
              label=""
              options={CancellationPromptOptions}
              onChange={val => updateReason(cancellationReason.id, "updateHasCustomerPrompt", val === "YES")}
              value={promptValue}
            />
          </td>
          <td style={{ width: 50, paddingRight: 0 }}>
            <IconContainer>
              <Tooltip content="Archive" preferredPosition="above">
                <span
                  onClick={() =>
                    updateReason(cancellationReason?.id!, "archiveReason", !cancellationReason?.isArchived)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={ArchiveMajor} color="base" />
                </span>
              </Tooltip>
              <Tooltip content="Delete" preferredPosition="above">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!isEmpty(associatedUserReasons)) {
                      setShowDeleteReasonModal(true);
                      return;
                    }
                    deleteReason(cancellationReason!.id);
                  }}
                >
                  <Icon source={DeleteMinor} color="base" />
                </span>
              </Tooltip>
            </IconContainer>
          </td>
        </tr>
      )}
      {!!showAssociatedAttempts && (
        <Modal
          open={showAssociatedAttempts}
          onClose={handleChange}
          title="Warning"
          primaryAction={{
            content: "Archive and add new reason",
            onAction() {
              updateReason(cancellationReason?.id!, "archiveReason", !cancellationReason?.isArchived);
              addReason(cancellationReasonInput.filter(r => !r.isArchived).length);
            },
          }}
          secondaryActions={[
            {
              content: "Continue renaming reason",
              onAction: handleChange,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <Banner status="warning">
                <p>
                  You have ({associatedUserReasons.length}) customer cancellation attempts associated with this
                  reason.
                </p>
                <p>
                  Renaming a cancellation reason in the same field will also retroactively affect all data
                  associated with this reason.
                </p>
                <p>
                  <strong>Instead</strong>, we recommend{" "}
                  <strong style={{ color: "#008060" }}>archiving this reason</strong> to preserve all historical
                  retention data and adding a new reason instead.
                </p>
              </Banner>
            </TextContainer>
          </Modal.Section>
        </Modal>
      )}

      {!!showDeleteReasonModal && (
        <Modal
          open={showDeleteReasonModal}
          onClose={() => setShowDeleteReasonModal(false)}
          title="Warning"
          primaryAction={{
            content: "Archive reason",
            onAction() {
              updateReason(cancellationReason?.id!, "archiveReason", !cancellationReason?.isArchived);
              setShowDeleteReasonModal(false);
            },
          }}
          secondaryActions={[
            {
              content: "Delete reason",
              destructive: true,
              onAction() {
                deleteReason(cancellationReason?.id!);
                setShowDeleteReasonModal(false);
              },
              outline: true,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <Banner status="critical">
                <p>
                  You have <strong style={{ color: "red" }}>({associatedUserReasons.length})</strong> customer
                  cancellation attempts associated with this reason.
                </p>
                <p>
                  Deleting this cancellation reason will provide a NULL value for all data associated with this
                  reason.
                </p>
                <p>
                  <strong>Instead</strong>, we recommend{" "}
                  <strong style={{ color: "#008060" }}>archiving this reason</strong> to preserve all historical
                  retention data.
                </p>
              </Banner>
            </TextContainer>
          </Modal.Section>
        </Modal>
      )}
    </React.Fragment>
  );
}

import { Icon, LegacyStack, Page, Spinner } from "@shopify/polaris";
import { InfoMinor } from "@shopify/polaris-icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { loadCancellationReasons } from "@vendor-app/app/_state/actionCreators/cancellationReason";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { CancellationReasonsSettings } from "./CancellationReasonsSettings";
import { loadPauseReasons } from "../../_state/actionCreators/pauseReason";

const DocLink = styled.div`
  bottom: 0;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
`;

export function AdminChurnPreventionRoute(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useSmartrrVendorDispatch();

  useEffect(() => {
    dispatch(loadCancellationReasons({}));
    dispatch(loadPauseReasons({}));
    setIsLoading(false);
  }, []);

  const authIsInitializing = useSmartrrVendorSelector(state => state.auth.isLoading);
  const cancellationReasons =
    useSmartrrVendorSelector(state => state.cancellationReasons.cancellationReasons) || [];
  const pauseReasons = useSmartrrVendorSelector(state => state.pauseReasons.pauseReasons) || [];

  return (
    <React.Fragment>
      <Page>
        {authIsInitializing || isLoading ? (
          <Spinner />
        ) : (
          <CancellationReasonsSettings cancellationReasons={cancellationReasons} pauseReasons={pauseReasons} />
        )}

        <DocLink className="docs-link">
          <LegacyStack distribution="center" spacing="tight">
            <Icon source={InfoMinor} color="highlight" />
            <span>
              Learn more about{" "}
              <a
                href="https://help.smartrr.com/docs/support/admin-portal/what-is-retention"
                rel="noreferrer"
                target="_blank"
              >
                retention.
              </a>
            </span>
          </LegacyStack>
        </DocLink>
      </Page>
    </React.Fragment>
  );
}

import { IPauseReason } from "@smartrr/shared/entities/CancellationRelationship";
import { ILoadPaginatedForOrg } from "@smartrr/shared/utils/paginatedQuery";

import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const loadPauseReasons =
  ({}: ILoadPaginatedForOrg): SmartrrThunkAction<
    "LOADING_PAUSE_REASONS" | "ERROR_LOADING_PAUSE_REASONS" | "LOADED_PAUSE_REASONS"
  > =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/pause-reasons"),
      () =>
        dispatch({
          type: "LOADING_PAUSE_REASONS",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_LOADING_PAUSE_REASONS",
          payload: {
            errorMessage: failure.message,
          },
        }),
      pauseReasons =>
        dispatch({
          type: "LOADED_PAUSE_REASONS",
          payload: {
            pauseReasons,
          },
        })
    );

export const savePauseReason =
  (
    newPauseReasons: IPauseReason[]
  ): SmartrrThunkAction<"SAVING_PAUSE_REASON" | "ERROR_SAVING_PAUSE_REASON" | "SAVED_PAUSE_REASON"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/pause-reason", {
        reqBody: { newPauseReasons },
      }),
      () =>
        dispatch({
          type: "SAVING_PAUSE_REASON",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_SAVING_PAUSE_REASON",
          payload: {
            errorMessage: failure.message,
          },
        }),
      pauseReasons =>
        dispatch({
          type: "SAVED_PAUSE_REASON",
          payload: {
            pauseReasons,
          },
        })
    );

export const updatePauseReasons =
  (
    pauseReasons: IPauseReason[]
  ): SmartrrThunkAction<"UPDATING_PAUSE_REASON" | "ERROR_UPDATING_PAUSE_REASON" | "UPDATED_PAUSE_REASON"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.putReq("/pause-reason/", {
        reqBody: { pauseReasons },
      }),
      () =>
        dispatch({
          type: "UPDATING_PAUSE_REASON",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_UPDATING_PAUSE_REASON",
          payload: {
            errorMessage: failure.message,
          },
        }),
      pauseReasons =>
        dispatch({
          type: "UPDATED_PAUSE_REASON",
          payload: {
            pauseReasons,
          },
        })
    );

export const deletePauseReasons =
  (
    reasonIdsToDelete: string[]
  ): SmartrrThunkAction<"DELETING_PAUSE_REASON" | "ERROR_DELETING_PAUSE_REASON" | "DELETED_PAUSE_REASON"> =>
  dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.postReq("/pause-reason-delete", {
        reqBody: {
          reasonIdsToDelete,
        },
      }),
      () =>
        dispatch({
          type: "DELETING_PAUSE_REASON",
          payload: undefined,
        }),
      failure =>
        dispatch({
          type: "ERROR_DELETING_PAUSE_REASON",
          payload: {
            errorMessage: failure.message,
          },
        }),
      pauseReasonIds =>
        dispatch({
          type: "DELETED_PAUSE_REASON",
          payload: {
            pauseReasonIds,
          },
        })
    );

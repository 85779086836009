import { Badge, Icon, LegacyStack, Link, Tooltip } from "@shopify/polaris";
import { ConversationMinor } from "@shopify/polaris-icons";
import {
  CancellationActionEnum,
  CancellationReasonTypeEnum,
  ICancellationReason,
  IPauseReason,
} from "@smartrr/shared/entities/CancellationRelationship";
import { ISODateString } from "@smartrr/shared/entities/ISODateString";
import { IOrganization } from "@smartrr/shared/entities/Organization";
import { IPurchaseStateWithCustomerRelationship } from "@smartrr/shared/entities/PurchaseState";
import { extractShopifyId, shopifyGidToNumber, viewShopifyId } from "@smartrr/shared/utils/ensureShopifyGid";
import { capitalize, isNil, startCase } from "lodash";
import React from "react";
import styled from "styled-components";

import { typedFrontendVendorApi } from "@smartrr/vendor-portal/src/utils/typedFrontendVendorApi";
import {
  navigateToCustomerDetails,
  navigateToSubscriptionDetails,
} from "@vendor-app/utils/navigateWithShopInQuery";

import { IUserSubscriptionCancellationProps, IUserSubscriptionPauseProps } from "../CancellationReasonsSettings";

const BadgeWrapper = styled.div`
  .Polaris-Badge {
    background: ${props => props.color};
  }
`;
export interface IRawCancellationRow {
  customer: {
    firstName: string | undefined;
    lastName: string | undefined;
    customerId: string | undefined;
  };
  subscriptionId: string | undefined;
  cancellation: {
    reasonText?: string;
    userNote?: string;
    action: string;
  };
  date: any;
}
export interface IRawPauseRow {
  customer: {
    firstName: string | undefined;
    lastName: string | undefined;
    customerId: string | undefined;
  };
  subscriptionId: string | undefined;
  pause: {
    reasonText?: string;
    userNote?: string;
  };
  date: any;
}

export const MAX_REASONS = 8;

export const CancellationActionOptions = Object.values(CancellationActionEnum).map(opt => {
  if (opt === CancellationActionEnum.CANCEL) {
    return {
      label: "None",
      value: "CANCEL",
    };
  }
  return {
    label: startCase(opt.toLowerCase()),
    value: opt,
  };
});

export const CancellationReasonTypeOptions = Object.values(CancellationReasonTypeEnum).map(opt => ({
  label: opt,
  value: opt,
}));

export const firstCancellationActionLabel = CancellationActionOptions[3].label;
export const firstCancellationReasonTypeLabel = CancellationReasonTypeOptions[0].label;

export const emptyReason: ICancellationReason = {
  id: "",
  reasonType: firstCancellationReasonTypeLabel!,
  text: { "en-US": "" },
  action: CancellationActionEnum.CANCEL,
  uiIdx: 0,
  hasCustomerPrompt: true,
  isArchived: false,
  discount: undefined,
};

export const emptyPauseReason: IPauseReason = {
  id: "",
  text: "",
  uiIdx: 0,
  hasCustomerPrompt: true,
  isArchived: false,
};

export const populateDefaultCancellationReasons = async () => {
  const defaultReasons: ICancellationReason[] = [
    {
      id: "",
      reasonType: firstCancellationReasonTypeLabel!,
      text: { "en-US": "Product is too expensive" },
      action: CancellationActionEnum.CANCEL,
      uiIdx: 0,
      hasCustomerPrompt: true,
      isArchived: false,
      discount: 0,
    },
    {
      id: "",
      reasonType: firstCancellationReasonTypeLabel!,
      text: { "en-US": "I have more than I need" },
      action: CancellationActionEnum.CANCEL,
      uiIdx: 1,
      hasCustomerPrompt: true,
      isArchived: false,
      discount: 0,
    },
    {
      id: "",
      reasonType: firstCancellationReasonTypeLabel!,
      text: { "en-US": "I need it sooner" },
      action: CancellationActionEnum.CANCEL,
      uiIdx: 2,
      hasCustomerPrompt: true,
      isArchived: false,
      discount: 0,
    },
    {
      id: "",
      reasonType: firstCancellationReasonTypeLabel!,
      text: { "en-US": "I want to try a different product" },
      action: CancellationActionEnum.CANCEL,
      uiIdx: 3,
      hasCustomerPrompt: true,
      isArchived: false,
      discount: 0,
    },
    {
      id: "",
      reasonType: firstCancellationReasonTypeLabel!,
      text: { "en-US": "I dont love the product" },
      action: CancellationActionEnum.CANCEL,
      uiIdx: 4,
      hasCustomerPrompt: true,
      isArchived: false,
      discount: 0,
    },
    {
      id: "",
      reasonType: firstCancellationReasonTypeLabel!,
      text: { "en-US": "I'm moving and need to change my address" },
      action: CancellationActionEnum.CANCEL,
      uiIdx: 5,
      hasCustomerPrompt: true,
      isArchived: false,
      discount: 0,
    },
    {
      id: "",
      reasonType: firstCancellationReasonTypeLabel!,
      text: { "en-US": "Other" },
      action: CancellationActionEnum.CANCEL,
      uiIdx: 6,
      hasCustomerPrompt: true,
      isArchived: false,
      discount: 0,
    },
  ];

  await typedFrontendVendorApi.postReq("/default-cancellation-reasons", {
    reqBody: {
      defaultReasons,
    },
  });
};

export const populateDefaultPauseReasons = async () => {
  const defaultPauseReasons: IPauseReason[] = [
    {
      id: "",
      hasCustomerPrompt: true,
      isArchived: false,
      text: "Product is too expensive",
      uiIdx: 0,
    },
    {
      id: "",
      hasCustomerPrompt: true,
      isArchived: false,
      text: "I want to try a different product",
      uiIdx: 1,
    },
    {
      id: "",
      hasCustomerPrompt: true,
      isArchived: false,
      text: "It doesn't meet my current budget",
      uiIdx: 2,
    },
    {
      id: "",
      hasCustomerPrompt: true,
      isArchived: false,
      text: "I'm taking a break",
      uiIdx: 3,
    },
    {
      id: "",
      hasCustomerPrompt: true,
      isArchived: false,
      text: "I'm going on vacation",
      uiIdx: 4,
    },
    {
      id: "",
      hasCustomerPrompt: true,
      isArchived: false,
      text: "Other",
      uiIdx: 5,
    },
  ];

  await typedFrontendVendorApi.postReq("/default-pause-reasons", {
    reqBody: {
      defaultPauseReasons,
    },
  });
};

// Filtering all cancelledPurchaseStates and userReasons by purchaseState.id to remove multiple occurrences of the same cancelled purchase state
// this is needed because we are combining user_subscription_cancellations_orm data and customer_purchase_state_orm data
export const filterByReference = (
  purchaseStates: IPurchaseStateWithCustomerRelationship[],
  userCancellations: IUserSubscriptionCancellationProps[]
) => {
  let res: IPurchaseStateWithCustomerRelationship[] = [];
  res = purchaseStates.filter((cps: IPurchaseStateWithCustomerRelationship) => {
    return !userCancellations.find((userCancellation: IUserSubscriptionCancellationProps) => {
      return userCancellation.action === CancellationActionEnum.CANCEL && userCancellation.st.id === cps.id;
    });
  });
  return res;
};

export const getReasonDateRow = (billingTime: any, date: any) => {
  const day = ISODateString.fromString(date).setZone(billingTime).toLocaleString({
    day: "2-digit",
    month: "long",
    year: "numeric",
  });
  const time = ISODateString.fromString(date).setZone(billingTime).toLocaleString({
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${day} ${time}`;
};

export const getReasonActionRow = (color: string, actionText: string, action: string) => {
  return (
    <BadgeWrapper color={color}>
      <Badge key={action} status="warning">
        {actionText}
      </Badge>
    </BadgeWrapper>
  );
};

const truncate = (str: string, n: number) => {
  return str?.length > n ? str.slice(0, Math.max(0, n - 1)) + "..." : str;
};

export const getCancellationReasonRow = (
  reasonCancellationText: string,
  formattedNote: string,
  userReasonNote?: string
) => {
  return (
    <LegacyStack>
      <LegacyStack.Item>{`"${truncate(reasonCancellationText, 25)}"`}</LegacyStack.Item>
      {!!userReasonNote && (
        <Tooltip preferredPosition="above" content={formattedNote}>
          <Icon source={ConversationMinor} color="base" />
        </Tooltip>
      )}
    </LegacyStack>
  );
};

export const getSubscriptionIdRow = (customerShopifyId: string, cpsShopifyId: string) => {
  return (
    <span
      onClick={() => navigateToSubscriptionDetails(cpsShopifyId!)}
      style={{ fontWeight: 500 }}
      className="Polaris-Link Polaris-Link"
    >
      {viewShopifyId(cpsShopifyId)}
    </span>
  );
};

export const getCustomerNameRow = (
  firstName: string,
  lastName: string,
  organization: IOrganization,
  customerShopifyId: number
) => {
  return (
    <Link
      key={`${capitalize(firstName)} ${capitalize(lastName)}`}
      onClick={() => navigateToCustomerDetails(extractShopifyId(customerShopifyId)!)}
    >
      <span style={{ fontWeight: 500 }}>
        {capitalize(firstName)} {capitalize(lastName)}
      </span>
    </Link>
  );
};

export const formatTextAndBadgeColor = (action: CancellationActionEnum) => {
  switch (action) {
    case CancellationActionEnum.CANCEL: {
      return ["Canceled", "#FED3D1"];
    }
    case CancellationActionEnum.SWAP: {
      return ["Swapped", "#A4E8F2"];
    }
    case CancellationActionEnum.GIFT: {
      return ["Gifted", "#FFD29D"];
    }
    case CancellationActionEnum.DISCOUNT: {
      return ["Discount", "#AEE9D0"];
    }
    case CancellationActionEnum.FREQUENCY_UPDATE: {
      return ["Frequency updated", "#EAFED1"];
    }
    case CancellationActionEnum.CHANGE_DATE: {
      return ["Date changed", "#D2D1FE"];
    }
    case CancellationActionEnum.SKIP: {
      return ["Skipped", "#E4E5E7"];
    }
    case CancellationActionEnum.CHANGE_ADDRESS: {
      return ["Address updated", "#FEF4D1"];
    }
    default: {
      return ["", ""];
    }
  }
};

export const formatCancellationDataforTable = (
  cancelledCps: IPurchaseStateWithCustomerRelationship[]
): IRawCancellationRow[] | undefined => {
  return cancelledCps.map((cps: IPurchaseStateWithCustomerRelationship) => {
    return {
      customer: {
        firstName: cps.custRel?.firstName,
        lastName: cps.custRel?.lastName,
        customerId: cps.custRel?.shopifyId,
      },
      cancellation: {
        action: CancellationActionEnum.CANCEL,
      },
      subscriptionId: cps.shopifyId,
      date: cps.cancelledAt,
    };
  });
};

export const formatUserDataforTable = (
  userReasons: IUserSubscriptionCancellationProps[]
): IRawCancellationRow[] | undefined => {
  return userReasons.map(reason => {
    return {
      customer: {
        firstName: reason.st.custRel?.firstName,
        lastName: reason.st.custRel?.lastName,
        customerId: reason.st.custRel?.shopifyId,
      },
      subscriptionId: reason.st.shopifyId,
      cancellation: {
        reasonText: reason.cancellationReason.text?.["en-US"]!,
        userNote: reason.reasonText,
        action: reason.action,
      },
      date: reason.createdDate,
    };
  });
};

export const formatUserPauseDataforTable = (
  userReasons: IUserSubscriptionPauseProps[]
): IRawPauseRow[] | undefined => {
  return userReasons.map(reason => {
    return {
      customer: {
        firstName: reason.st.custRel?.firstName,
        lastName: reason.st.custRel?.lastName,
        customerId: reason.st.custRel?.shopifyId,
      },
      subscriptionId: reason.st.shopifyId,
      pause: {
        reasonText: reason?.pauseReason?.text,
        userNote: reason?.reasonText,
      },
      date: reason.createdDate,
    };
  });
};

export const formatUserReasonsTableRows = (
  userReasonsTableData: IRawCancellationRow[] | undefined,
  selectedDates: {
    start: Date;
    end: Date;
  },
  organization: IOrganization | null
) => {
  if (userReasonsTableData) {
    const incrementedEndDate = new Date(selectedDates.end);
    incrementedEndDate.setDate(incrementedEndDate.getDate() + 1);
    return userReasonsTableData
      .filter(row => new Date(row.date) >= selectedDates.start && new Date(row.date) <= incrementedEndDate)
      .sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      })
      .map(row => {
        const [actionText, reasonBadgeColor] = formatTextAndBadgeColor(
          row?.cancellation?.action! as CancellationActionEnum
        );
        const text = `${capitalize(row.customer.firstName!)} added:"${row?.cancellation?.userNote}"`;
        const gidToNumber = shopifyGidToNumber(row.customer.customerId!);
        const customerName = getCustomerNameRow(
          row.customer.firstName!,
          row.customer.lastName!,
          organization!,
          gidToNumber
        );
        const subscriptionId = getSubscriptionIdRow(row.customer.customerId!, row.subscriptionId!);

        const cancellationReason = isNil(row?.cancellation?.reasonText) ? (
          <div key={row.subscriptionId} style={{ color: "gray" }}>
            No Reason Selected
          </div>
        ) : (
          getCancellationReasonRow(row?.cancellation?.reasonText!, text, row?.cancellation?.userNote)
        );
        const reasonDate = getReasonDateRow(organization?.billingTimezone, row.date);
        const reasonAction = getReasonActionRow(reasonBadgeColor, actionText, row?.cancellation?.action!);

        return [customerName, subscriptionId, cancellationReason, reasonDate, reasonAction];
      });
  }
};

export const formatUserPauseReasonsTableRows = (
  userReasonsPauseTableData: IRawPauseRow[] | undefined,
  selectedDates: {
    start: Date;
    end: Date;
  },
  organization: IOrganization | null
) => {
  if (userReasonsPauseTableData) {
    const incrementedEndDate = new Date(selectedDates.end);
    incrementedEndDate.setDate(incrementedEndDate.getDate() + 1);
    return userReasonsPauseTableData
      .filter(row => new Date(row.date) >= selectedDates.start && new Date(row.date) <= incrementedEndDate)
      .sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      })
      .map(row => {
        const text = `${capitalize(row.customer.firstName!)} added:"${row.pause.userNote}"`;
        const gidToNumber = shopifyGidToNumber(row.customer.customerId!);
        const customerName = getCustomerNameRow(
          row.customer.firstName!,
          row.customer.lastName!,
          organization!,
          gidToNumber
        );
        const subscriptionId = getSubscriptionIdRow(row.customer.customerId!, row.subscriptionId!);

        const cancellationReason = isNil(row.pause.reasonText) ? (
          <div key={row.subscriptionId} style={{ color: "gray" }}>
            No Reason Selected
          </div>
        ) : (
          getCancellationReasonRow(row.pause.reasonText!, text, row.pause.userNote)
        );
        const reasonDate = getReasonDateRow(organization?.billingTimezone, row.date);
        const reasonAction = getReasonActionRow("lightgray", "Paused", "pause");

        return [customerName, subscriptionId, cancellationReason, reasonDate, reasonAction];
      });
  }
};

export const formatCancellationReasonTableRows = (
  cancellationReasons: ICancellationReason[] | undefined,
  userReasons: IUserSubscriptionCancellationProps[] | undefined,
  selectedDates: {
    start: Date;
    end: Date;
  }
) => {
  if (cancellationReasons && userReasons) {
    return cancellationReasons
      .map(reason => {
        const incrementedEndDate = new Date(selectedDates.end);
        incrementedEndDate.setDate(incrementedEndDate.getDate() + 1);
        const reasonId = reason.id;
        const reasonCount = userReasons
          .filter(
            row =>
              new Date(row.createdDate) >= selectedDates.start && new Date(row.createdDate) <= incrementedEndDate
          )
          .filter(r => r.cancellationReason.id === reasonId).length;
        return [reason.text?.["en-US"], reasonCount];
      })
      .sort((a, b) => (b[1] as number) - (a[1] as number));
  }
};

export const formatPauseReasonTableRows = (
  pauseReasons: IPauseReason[] | undefined,
  userPauseReasons: IUserSubscriptionPauseProps[] | undefined,
  selectedDates: {
    start: Date;
    end: Date;
  }
) => {
  if (pauseReasons && userPauseReasons) {
    const incrementedEndDate = new Date(selectedDates.end);
    incrementedEndDate.setDate(incrementedEndDate.getDate() + 1);
    return pauseReasons
      .map(reason => {
        const reasonId = reason.id;
        const reasonCount = userPauseReasons
          .filter(
            row =>
              new Date(row.createdDate) >= selectedDates.start && new Date(row.createdDate) <= incrementedEndDate
          )
          .filter(r => r.pauseReason.id === reasonId).length;
        return [reason.text, reasonCount];
      })
      .sort((a, b) => (b[1] as number) - (a[1] as number));
  }
};

export const updateHasVisitedRetention = async () => {
  await typedFrontendVendorApi.putReq("/retention-config", {
    reqBody: {
      hasVisitedRetention: true,
    },
  });
};
export const getDateSixMonthsAgo = () => {
  const sixMonthsAgo = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);
  return sixMonthsAgo;
};

export const getDateThreeMonthsAgo = () => {
  const threeMonthsAgo = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
  return threeMonthsAgo;
};

export const getDateSevenDaysAgo = () => {
  const sevenDaysAgo: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  return sevenDaysAgo;
};

export const setMidnightTime = (d: Date) => {
  return new Date(d.setHours(23, 59, 59));
};

import { Banner, Icon, Modal, Select, TextContainer, TextField, Tooltip } from "@shopify/polaris";
import { ArchiveMajor, DeleteMinor } from "@shopify/polaris-icons";
import { IPauseReason } from "@smartrr/shared/entities/CancellationRelationship";
import { isEmpty } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";

import { IUserSubscriptionPauseProps } from "../CancellationReasonsSettings";

const PromptOptions = [
  {
    label: "YES",
    value: "YES",
  },
  {
    label: "NO",
    value: "NO",
  },
];

const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 6.5px;
`;

interface PauseReasonProps {
  pauseReason?: IPauseReason;
  deleteReason: (id: string) => void;
  addReason: (reasonsAmount?: number) => void;
  updateReason: (
    id: string,
    action: "updateReasonText" | "archiveReason" | "updateHasCustomerPrompt",
    value: string | boolean
  ) => void;
  pauseReasonInput: IPauseReason[];
  userReasons: IUserSubscriptionPauseProps[];
}

export function PauseReason({
  pauseReason,
  deleteReason,
  updateReason,
  pauseReasonInput,
  addReason,
  userReasons,
}: PauseReasonProps) {
  const [showAssociatedAttempts, setShowAssociatedAttempts] = useState(false);
  const [showDeleteReasonModal, setShowDeleteReasonModal] = useState(false);
  const [hasWarned, setHasWarned] = useState(false);

  const associatedUserPauseReasons =
    userReasons && userReasons.filter(userReason => userReason.pauseReason.id === pauseReason?.id);

  const handleChange = useCallback(
    () => setShowAssociatedAttempts(prevState => !prevState),
    [showAssociatedAttempts]
  );

  const promptValue = useMemo(() => {
    return pauseReason && pauseReason?.hasCustomerPrompt ? "YES" : "NO";
  }, [pauseReason]);

  return (
    <React.Fragment>
      {!!pauseReason && (
        <tr>
          <td style={{ width: 600 }}>
            <TextField
              autoComplete="off"
              label=""
              value={pauseReason.text}
              onChange={val => {
                if (!isEmpty(associatedUserPauseReasons) && !hasWarned) {
                  setShowAssociatedAttempts(true);
                  setHasWarned(true);
                  return;
                }
                updateReason(pauseReason.id, "updateReasonText", val);
              }}
              maxLength={40}
              showCharacterCount
            />
          </td>

          <td style={{ width: 78 }}>
            <Select
              label=""
              options={PromptOptions}
              onChange={val => updateReason(pauseReason.id, "updateHasCustomerPrompt", val === "YES")}
              value={promptValue}
            />
          </td>

          <td style={{ width: 49, paddingRight: 0 }}>
            <IconContainer>
              <Tooltip content="Archive" preferredPosition="above">
                <span
                  onClick={() => updateReason(pauseReason?.id!, "archiveReason", !pauseReason?.isArchived)}
                  style={{ cursor: "pointer" }}
                >
                  <Icon source={ArchiveMajor} color="base" />
                </span>
              </Tooltip>
              <Tooltip content="Delete" preferredPosition="above">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!isEmpty(associatedUserPauseReasons)) {
                      setShowDeleteReasonModal(true);
                      return;
                    }
                    deleteReason(pauseReason!.id);
                  }}
                >
                  <Icon source={DeleteMinor} color="base" />
                </span>
              </Tooltip>
            </IconContainer>
          </td>
        </tr>
      )}
      {!!showAssociatedAttempts && (
        <Modal
          open={showAssociatedAttempts}
          onClose={handleChange}
          title="Warning"
          primaryAction={{
            content: "Archive and add new reason",
            onAction() {
              updateReason(pauseReason?.id!, "archiveReason", !pauseReason?.isArchived);
              addReason(pauseReasonInput.filter(r => !r.isArchived).length);
            },
          }}
          secondaryActions={[
            {
              content: "Continue renaming reason",
              onAction: handleChange,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <Banner status="warning">
                <p>
                  You have ({associatedUserPauseReasons.length}) customer pause attempts associated with this
                  reason.
                </p>
                <p>
                  Renaming a pause reason in the same field will also retroactively affect all data associated
                  with this reason.
                </p>
                <p>
                  <strong>Instead</strong>, we recommend{" "}
                  <strong style={{ color: "#008060" }}>archiving this reason</strong> to preserve all historical
                  retention data and adding a new reason instead.
                </p>
              </Banner>
            </TextContainer>
          </Modal.Section>
        </Modal>
      )}

      {!!showDeleteReasonModal && (
        <Modal
          open={showDeleteReasonModal}
          onClose={() => setShowDeleteReasonModal(false)}
          title="Warning"
          primaryAction={{
            content: "Archive reason",
            onAction() {
              updateReason(pauseReason?.id!, "archiveReason", !pauseReason?.isArchived);
              setShowDeleteReasonModal(false);
            },
          }}
          secondaryActions={[
            {
              content: "Delete reason",
              destructive: true,
              onAction() {
                deleteReason(pauseReason?.id!);
                setShowDeleteReasonModal(false);
              },
              outline: true,
            },
          ]}
        >
          <Modal.Section>
            <TextContainer>
              <Banner status="critical">
                <p>
                  You have <strong style={{ color: "red" }}>({associatedUserPauseReasons.length})</strong>{" "}
                  customer pause attempts associated with this reason.
                </p>
                <p>
                  Deleting this pause reason will provide a NULL value for all data associated with this reason.
                </p>
                <p>
                  <strong>Instead</strong>, we recommend{" "}
                  <strong style={{ color: "#008060" }}>archiving this reason</strong> to preserve all historical
                  retention data.
                </p>
              </Banner>
            </TextContainer>
          </Modal.Section>
        </Modal>
      )}
    </React.Fragment>
  );
}

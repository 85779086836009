import { Icon, Select, TextField } from "@shopify/polaris";
import { UndoMajor } from "@shopify/polaris-icons";
import {
  CancellationActionEnum,
  ICancellationReason,
  IPauseReason,
} from "@smartrr/shared/entities/CancellationRelationship";
import React from "react";
import styled from "styled-components";

import { CancellationActionOptions } from "../utils";

interface ArchivedReasonProps {
  cancellationReason?: ICancellationReason;
  pauseReason?: IPauseReason;
  updateReason?: (
    id: string,
    action:
      | "updateReasonText"
      | "updateAction"
      | "updateReasonDiscount"
      | "archiveReason"
      | "updateHasCustomerPrompt",
    value: string | CancellationActionEnum | number | boolean
  ) => void;
  updatePauseReason?: (
    id: string,
    action: "updateReasonText" | "archiveReason" | "updateHasCustomerPrompt",
    value: string | boolean
  ) => void;
  pause?: boolean;
}

const IconContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  /* .Polaris-Icon {
    margin: 0;
  } */
`;

export const ArchivedReason = ({
  cancellationReason,
  pauseReason,
  updateReason,
  updatePauseReason,
  pause,
}: ArchivedReasonProps) => {
  return (
    <React.Fragment>
      <tr>
        <td style={{ width: pause ? 600 : 365 }}>
          <TextField
            autoComplete="off"
            label=""
            value={pause ? pauseReason?.text : cancellationReason?.text?.["en-US"] || ""}
            disabled={true}
            showCharacterCount
            maxLength={40}
          />
        </td>
        {!pause && (
          <React.Fragment>
            <td style={{ width: 195 }}>
              <Select
                label=""
                value={cancellationReason?.action}
                disabled={true}
                options={CancellationActionOptions}
              />
            </td>
            <td style={{ width: 79 }}>
              <TextField
                autoComplete="off"
                label=""
                value={
                  cancellationReason?.action === CancellationActionEnum.DISCOUNT
                    ? cancellationReason?.discount?.toString()!
                    : ""
                }
                onChange={() => null}
                maxLength={2}
                type="tel"
                disabled
                suffix="%"
              />
            </td>
          </React.Fragment>
        )}

        <td style={{ width: 79 }}>
          <Select label="" value={cancellationReason?.hasCustomerPrompt ? "YES" : "NO"} disabled />
        </td>
        <td style={{ width: 117, paddingRight: 0 }}>
          <IconContainer>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (pause) {
                  updatePauseReason &&
                    updatePauseReason(pauseReason?.id!, "archiveReason", !pauseReason?.isArchived);
                }
                updateReason &&
                  updateReason(cancellationReason?.id!, "archiveReason", !cancellationReason?.isArchived);
              }}
            >
              <Icon source={UndoMajor} color={"base"} />
            </span>
          </IconContainer>
        </td>
      </tr>
    </React.Fragment>
  );
};
